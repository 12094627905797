<template>
  <div class="page-provenance">
    <h1>Galleries Provenance Record</h1>
    <p>
      This page presents the provenance record of each Gallery that will ever exist.
      Each full resolution Gallery image is firstly hashed using SHA-256 algorithm.
      A combined string is obtained by concatenating SHA-256 of each Gallery image in
      the specific order as listed below. The final proof is obtained by SHA-256 hashing
      this combined string. This is the final provenance record stored on the smart contract
    </p>

    <div class="page-provenance__grey-section">
      <p>Finalized Starting Index: <b class="monospace">681</b></p>

      <p>
        Each Gallery token ID is assigned to an artwork image from the original sequence with this formula:
        <br>
        <span class="monospace">
          (tokenId + startingIndex) % 4000 => Image Index From the Original Sequence
        </span>
      </p>

      <p>Final Proof Hash: <b class="monospace">{{getProvenanceData.finalProofHash}}</b></p>
    </div>

    <div class="concatenated-hash-string-wrapper">
      <b>Concatenated Hash String</b>

      <div class="monospace">
        {{getProvenanceData.concatenatedHashString}}
      </div>
    </div>

    <p class="page-provenance__table-description">
      The table below lists the original index, assigned Gallery Token ID, SHA256 Hash output
      and link of each Gallery image
    </p>

    <el-table :data="getTableData">
      <el-table-column prop="imageId" label="Image ID" width="100"></el-table-column>
      <!-- <el-table-column prop="tokenId" label="Token ID" width="100"></el-table-column> -->
      <el-table-column prop="sha256" label="SHA-256"></el-table-column>
      
      <!-- <el-table-column label="Image Link" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.imageLink == '-'">-</span>
          <a v-else :href="scope.row.imageLink">
            {{ scope.row.imageLink.replace('https://galleries.boredhash.com', '') }}
          </a>
      </template> -->
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

import { Table, TableColumn} from 'element-ui';
import provenanceDataJson from '@/assets/provenance.json';

export default {
  name: 'Provenance',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
  },

  data() {
    return {
      isLoadingMainBlockhainData: true,
      totalSupply: null,
    };
  },

  mounted() {
    if (this.isLoadingMainBlockhainData) {
      if (this.web3.networkId) {
        this.getBlockchainData();
      }
    }
  },

  watch: {
    'web3.networkId': {
      handler(newWeb3, oldWeb3) {
        if (this.isLoadingMainBlockhainData) {
          this.getBlockchainData();
        }
      },
      deep: true
    },
  },

  methods: {
    async getBlockchainData() {
      const _totalSupply = await this.web3.galleriesContractInstance.methods.totalSupply().call();
      this.totalSupply = Number(_totalSupply);
      this.isLoadingMainBlockhainData = false;
    },
  },

  computed: {
    web3 () {
      return this.$store.state.web3;
    },

    getProvenanceData() {
      return provenanceDataJson;
    },

    getTableData() {
      return this.getProvenanceData.imagesHashes.map((x) => {

        let imageLink = '-';
        // if (x.imageId < this.totalSupply) {
        //   imageLink = `https://galleries.boredhash.com/api/gallery-image/${x.imageId}`;
        // }

        return {
          imageId: x.imageId,
          tokenId: '-',
          sha256: x.sha256,
          imageLink,
        }
      });
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .page-provenance {
    padding-top: 50px;
    padding-bottom: 100px;
    max-width: 1100px;
    margin: auto;

    h1 {
      text-align: center;
    }

    .monospace {
      font-family: 'Inconsolata', monospace;
    }

    .el-table {
      max-width: 100%;
      margin: auto;

      .cell {
        text-align: center;
        font-family: 'Inconsolata', monospace;
      }
    }
  }

  .page-provenance__grey-section {
    background: rgba(227, 230, 238, 0.7);
    text-align: center;
    padding: 40px 0;
    margin: 60px 0;

    p {
      margin: 40px 0;
    }
  }

  .concatenated-hash-string-wrapper {
    text-align: center;
    & > div {
      max-width: 900px;
      margin: auto;
      overflow-x: scroll;
      font-size: 15px;
    }
  }

  .page-provenance__table-description {
    margin: 80px 0px 20px;
    text-align: center;
  }

</style>
